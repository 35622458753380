<section class="section1">
    <div class="container-fluid">
        <div class="row top">
            <div class="col-md-4 col-sm-6 col-6">
                <div class="logo">
                    <img src="assets/img/logo.png" alt="">
                </div>
            </div>
            <div class="col-md-4 col-sm-12 col-12 text-center hidmd">
                <h1 class="titl">
                    تقديم طلبات التعيين - دفعة 2023
                </h1>
            </div>
            <div class="col-md-4 col-sm-6  col-6 text-end">
                <h6> جمهوريـــة مصـــر العربيـــة </h6>
                <h6> المجلس الأعلى للنيابة الإدارية </h6>
            </div>
            <div class="col-md-4 col-sm-12 col-12 text-center hidsm">
                <h1 class="titl">
                    تقديم طلبات التعيين - دفعة  2023
                </h1>
            </div>
        </div>


        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <h5>
                        يجب وبنحو مشدد مرعاه الاتي: -
                    </h5>
                    <div>
                        <ul>
                            <li>
                                	كتابة الاسم رباعي وباللغة العربية من واقع بطاقة الرقم القومي - 
                                حيث انه لن يسمح بإجراء أي تعديلات على الاسم بعد أدخالة.
                                
                            </li>
                            <li>
                               	كتابة الرقم القومي المكون من (14) رقم بصورة دقيقة وصحيحة كما هو موجود 
                                ببطاقة الرقم القومي حيث انه لن يسمح بإجراء أي تعديلات على الرقم القومي بعد أدخالة.
                            </li>
                            <li>
                                
                                	التأكيد على ضرورة ادخال البريد الإلكتروني بصورة صحيحة وسليمة 
                                باللغة الإنجليزية حيث ان البريد الإلكتروني هو وسيلة التواصل الوحيدة 
                               بين المتقدم والنيابة.
                               
                            </li>
                            <li>
                               	كتابة عنوان الإقامة بصورة دقيقة (محافظة الإقامة – 
قسم شرطة الإقامة) ورقم العقار واسم الشارع المقيم به.


                            </li>
                            <li>
                                	تحديد الجامعة بكل دقة وتحديد الشعبة (إنجليزي – فرنساوى – عربي) 
من واقع شهادة التخرج - حيث انه لن يسمح بإجراء أي تعديلات على الجامعة بعد ادخالها.

</li>
                            <li>
                                	كتابة المجموع الحاصل علية وكتابة المجموع الكلى 
من واقع شهادة التخرج - حيث انه لن يسمح بإجراء أي تعديلات على المجموع بعد إدخاله.

</li>
                            <li>
                                	تحديد سنة التخرج بكل دقة - حيث انه لن يسمح بإجراء أي تعديلات 
على سنة الدفعة بعد تحديدها.

</li>
<li>
   بالنسبة لوظيفة الوالد و الوالدة في حالة المعاش او الوفاة يتم ذكر الوظيفة السابقة تفصيلا .

</li>

                            <!-- <li>
                                إتمام عملية دفع مبلغ 506 جنية نظير استكمال عملية التسجيل وطباعة إيصال السداد المكون
                                من جزئين
                                (نسخة المتقدم – ونسخة استلام الملف) واحضاره يوم سحب الملف كشرط أساسي لسحب الملف.
                            </li> -->
                            <li>
                                	استكمال تسجيل جميع البيانات المطلوبة في كل الصفحات 
مع مراعاة في حالة عدم وجود أقارب للمرشح يثبت بالخانة المخصصة عبارة لا يوجد.

</li>
                            <li>
                                	طباعة نسخة مبدئية للمراجعة فقط وتعديل وتدقيق البيانات المسجلة بها، وبعد الانتهاء 
من التعديل والتدقيق يجب عليك طباعة النسخة النهائية من وثيقة التعارف بعدد (5 نسخ) والتوقيع على كل ورقة من الخمس نسخ.


                            </li>
                        </ul>

                        <h5> ملحوظة </h5>
                        <ul>
                            <li>
                                •	البيانات المسجلة مسئولية المتقدم ولا يوجد أي تدخل بشرى من النيابة الإدارية 
بأي صورة أي كانت بداية من عملية التسجيل وحتى طباعة النسخة النهائية.


                            </li>
                            <li>
                                •	لن يتم اجراء أي عملية تسجيل بعد المواعيد المحددة بالإعلان.
                            </li>
                        </ul>

                    </div>
                </div>
            </div>


            
            <div class="col-md-4">
                <div class="card">
                    <h5>
                        إجراءات سحب وتقديم الملف
                    </h5>
                    <div>
                        <ul>
                            <li>
                                •	تسحب الملفات خلال الفترة من 16/12/2023 
حتس 10/01/2024 سداد مبلغ ( 1200 ) ألف ومئتان جنيه  
عند سحب الملف عن طريق بطاقة (فيزا كارت) بها مبلغ أكثر 
من (1200  جنيه).

</li>

                            <li>
                                •	يتعين على المتقدم احضار كافة الأوراق والمستندات المطلوب تقديمها داخل الملف معه في المواعيد المحددة لسحب الملفات.

                            </li>

                            <li>
                                •	حيث سيتم سحب الملفات وتقديمها مرفقاً بها الأوراق والمستندات المطلوبة في نفس يوم سحب الملف وفقا للمواعيد المحددة لكل جامعة لدفعة 2023
                                 <!-- والموضحة بالجدول التالي: - -->
                             </li>


                        </ul>

                    </div>

                    


                    <div>

                        <table class="table table-bordered table-sm" style="display: none;">
                            <tr>
                                <td>
                                    <p>الجامعة</p>
                                </td>
                                <td>
                                    <p>سحب وتقديم الملفات (في نفس اليوم)</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> القاهـــرة - ذكـــــــور</p>
                                </td>
                                <td>
                                    <p>السبت   16/ 2023/12</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> القاهــــرة - انــــــاث</p>
                                </td>
                                <td>
                                    <p>  الاحد   17/ 2023/12</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> عين شمـــس – ذكـــــــور</p>
                                </td>
                                <td>
                                    <p>  الاثنين   18/ 2023/12</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> عين شمـــس – انــــــاث</p>
                                </td>
                                <td>
                                    <p>الثلاثاء   2023/12/19</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> حلــــــــوان</p>
                                </td>
                                <td>
                                    <p>الاربعاء    2023/12/20</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> الزقازيـــق – أزهــر دمنهـــور</p>
                                </td>
                                <td>
                                    <p>السبت   2023/12/23</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> بنهـــــا - أزهر طنطـــا</p>
                                </td>
                                <td>
                                    <p>الاحد   2023/12/24</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>المنوفية شبيــن – الســـادات </p>
                                </td>
                                <td>
                                    <p>الاثنين    2023/12/25</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p> طنطـــــا</p>
                                </td>
                                <td>
                                    <p>الثلاثاء    2023/12/26</p>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <p> المنصـــــورة</p>
                                </td>
                                <td>
                                    <p>الاربعاء     2023/12/27</p>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <p> دميـــاط – بورسعيـــد - أزهر تفهنـــا</p>
                                </td>
                                <td>
                                    <p>السبت    2023/12/30</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p> الاسكندريـــــة</p>
                                </td>
                                <td>
                                    <p>الاحد    2023/12/31</p>
                                </td>
                            </tr>



                            <tr>
                                <td>
                                    <p>الفيـــــوم – أزهــر اسيـــوط</p>
                                </td>
                                <td>
                                    <p>الاثنين    2024/02/01</p>
                                </td>
                            </tr>




                            <tr>
                                <td>
                                    <p> بنى سويـــــف </p>
                                </td>
                                <td>
                                    <p>الثلاثاء    2024/01/02</p>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <p>المنيا - ازهر المنيا – اســـــوان</p>
                                </td>
                                <td>
                                    <p>الاربعاء    2024/01/03</p>
                                </td>
                            </tr>




                            <tr>
                                <td>
                                    <p> اسيــــــــوط</p>
                                </td>
                                <td>
                                    <p>السبت    2024/01/06</p>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <p> سوهـــــــاج</p>
                                </td>
                                <td>
                                    <p>الاحد    2024/01/07</p>
                                </td>
                            </tr>



                            <tr>
                                <td>
                                    <p> جنـــــوب الــوادى</p>
                                </td>
                                <td>
                                    <p>الاثنين    2024/01/08</p>
                                </td>
                            </tr>



                            <tr>
                                <td>
                                    <p> أزهر القاهـــرة - أزهر المنصـــور</p>
                                </td>
                                <td>
                                    <p>الثلاثاء    2024/01/09</p>
                                </td>
                            </tr>



                            <tr>
                                <td>
                                    <p> الشرطــــة - الجامعة البريطانية - الجامعة الالمانية - 
                                        الاكاديمية العربية للعلوم والتكنولوجيا والنقل البحرى - 
                                        أزهر الاسكندرية - فاروس
                                        </p>
                                </td>
                                <td>
                                    <p>الاربعاء    2024/01/10</p>
                                </td>
                            </tr>



                            
                        </table>
                        <br>
                    
                    </div>




                    <div>
                        <br>
                        <br>
                        
                      
                        <br>
                        <h5> ملحوظة </h5>
                        <ul>
                            <li>

                                من فضلك يجب مراجعة المواعيد المخصصة لكل جامعة بكل دقة
                                مع مراعاة انه يجب الحضور فى الميعاد المخصص لجامعتك

                            </li>

                        </ul>
                    </div>
                </div>
            </div>



            
            <div class="col-md-4">
                <div class="card">
                    <h5>
                        بيانات الأوراق والمستندات المطلوب تقديمها داخل الملف
                    </h5>
                    <div>
                        <ul>


                            
                            <li>
                               	طلب تعيين باسم معالي المستشار / رئيس هيئة النيابة الإدارية 
(يتم طباعة الطلب مع النسخة النهائية من الوثيقة وذلك من خلال الموقع الإلكتروني الخاص بتسجيل البيانات والتوقيع علية بخط اليد) 

</li>

                            <li>
                                	شهادة التخرج المؤقتة مبيناً بها مجموع الدرجات (لا يقبل الملف بدون احضارها – شرط أساسي لقبول الملف)

                            </li>
                            <li>
                               	شهادة بتقديرات سنوات الدراسة مثبتاً بها مجموع درجات كل سنة 
على حده (لا يقبل الملف بدون احضارها – شرط أساسي لقبول الملف)
</li>
                            <li>
                               	صحيفة الحالة الجنائية للمتقدم.
                             </li>
                            <li>
                               	تقديم ما يثبت الموقف من التجنيد او صورة شهادة الاعفاء منه بالنسبة للذكور وشهادة تأدية الخدمة العامة للإناث او الاعفاء منها </li>
                            <li>
                                	صورة بطاقة الرقم القومي للمتقدم (يشترط ان تكون سارية)
                            </li>
                            <li>
                                	صورة بطاقة الرقم القومي للوالد / الوالدة (يشترط ان تكون سارية)
                            </li>
                            <li>
                                صحيفة الحالة الجنائية لوالد المتقدم - وفي حالة سفرة (يقدم شهادة تحركات من الجوازات ) وتعهد بأرفاق صحيفة الحالة الجنائية عقب وصولة للبلاد وفي حالة الوفاة يقدم صورة شهادة الوفاة. 
                        </li>

                            <li>
                                	شهادة الميلاد او مستخرج رسمي منها
                            </li>
                            <li>
                               	عدد 8 صور فوتوغرافية حديثة للمتقدم.
                             </li>
                             <li>
                                	احضار صورة فوتوغرافية طولية من الرأس للقدمين للمتقدم 
                                    مقاس  13 * 18 سم. 
                                
                             </li>

                       


                        </ul>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>

                        <h5>
                            النيابة الادارية تحذر وبشدة انها غير مسئوله عن اى بيانات او تصريحات او تعليمات متداولة خارج
                            هذا الموقع
                            وان البيانات والتعليمات الرسمية تصدر فقط على هذا الموقع

                        </h5>

                        <h5>
                            ملحوظة هامة لخريجي كلية الشرطة:
على من يرغب في التقدم من خريجي كلية الشرطة دفعة 2023 احضار افادة من كلية الشرطة موضح بها اسم المتقدم وسنة 
التخرج والمجموع الحاصل علية  من المجموع الكلى والنسبة المئوية على ان تكون موجهه للنيابة الإدارية ولن يقبل الملف دونها.
                        </h5>

                        <br>


                        <h5> ملحوظة </h5>
                        <ul>
                            <li>
                                من فضلك تأكد من تجهيز جميع المستندات المبينة عاليه واحضارها معك عند سحب الملف فى الميعاد
                                المحدد لكل جامعة
                            </li>

                        </ul>

                    </div>
                </div>
            </div>

            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="card bot">
                    <br>
                    <!-- <h4 class="text-center">
                        برجاء الانتظار والمتابعة لاحقا ...
                    </h4> 
                 
                    <h4 class="text-center">
                        سيتم فتح باب التقديم لدفعه 2023 في 11-11-2023
                    </h4> -->
                    <div>
                        <!-- <a class="new-account" routerLink="/register"> تسجيل جديد لدفعة 2023 </a> -->

                        <!-- <a class="new-account" href="./assets/introdocument2023.pdf" download="introdocument2023">تحميل وثيقة تعارف دفعة 2023</a> -->

                        <!-- <h5> خالص التمنيات ب التوفيق  -  تم غلق التسجيل الالكتروني (تسجيل جديد) بنهاية يوم الاحد الموافق 24-11-2023    </h5> -->
                    </div>

                    <br>

                    <div>
                        <a class="new-account" routerLink="/login"> تسجيل دخول لدفعة 2023 </a>

                        <h5> خالص التمنيات ب التوفيق  -  تم غلق التسجيل الالكتروني (تسجيل جديد) وتحديث البيانات بنهاية يوم الجمعة الموافق 24-11-2023    </h5>
                        <!-- <h5> خالص التمنيات ب التوفيق  -  تم غلق التسجيل الالكتروني (تسجيل جديد) بنهاية يوم الاحد الموافق 06-03-2023    </h5> -->
                    </div>

                </div>
            </div>

        </div>
    </div>
</section>